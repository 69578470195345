import logo from '../img/spendora/spendora-logo-large.png';
import downloadOniosAppStore from '../img/download-on-ios-app-store.svg';
import downloadOnMacAppStore from '../img/download-on-mac-app-store.svg';
import '../App.css';

function Spendora() {
  return (
    <article style={{padding: '1rem'}}>
      <header>
        <img src={logo} alt="spendora app logo" className="SpendoraAppLogo" />
        <h1>Spendora</h1>
        <p>Simplify your finance management! Track expenses, manage bank accounts, visualize transactions with charts, and import CSV files. Manage your budget efficiently!</p>
        <img src={downloadOniosAppStore} alt="Download on iOS App Store"/>
        <img src={downloadOnMacAppStore} alt="Download on Mac App Store" style={{marginLeft: '0.5rem'}}/>
      </header>
      <p> Welcome to our all-new expense tracker and bank account manager for iOS and macOS. This compact and easy-to-use app is your personal financial assistant. </p>

      <p>Features:</p>
      <ul>
        <li>Add Multiple Accounts: Seamlessly manage multiple bank accounts. View your complete financial picture in one place.</li>
        <li>Record Transactions: Log your income and expenses in a snap.</li>
        <li>CSV or OFX Import: Include past transactions conveniently with CSV or OFX import.</li>
        <li>Visualize with Charts: Get a clear understanding of your spending habits with engaging charts.</li>
      </ul>
      <p>With a clean, user-friendly interface, this app makes managing finances stress-free. Start mastering your budget today!</p>
      <div id="privacy-policy">
        <h2>Privacy Policy</h2>
        <h3>1. Introduction</h3>
        Welcome to Spendora, our all-new expense tracker and bank account manager for iOS and macOS. Developed by Lilohass, this compact and easy-to-use app aims to be your personal financial assistant. We value your trust, and this Privacy Policy outlines how we collect, use, and protect your personal information.

        <h3>2. Data Collection</h3>
        We collect only the iCloud user ID to store and link user data in the database. No other personal or sensitive information is collected by Spendora.

        <h3>3. Purpose of Data Collection</h3>
        The iCloud user ID is used to ensure that users' data is stored securely and can be accessed, updated, or deleted by the user as required.

        <h3>4. Data Storage</h3>
        All user data linked with the iCloud user ID is securely stored in the iCloud database, ensuring that your information is safe and accessible only by you.

        <h3>5. Data Sharing</h3>
        We do not share, sell, lease, or distribute the iCloud user ID or any other personal data with third-party entities. Your trust is of utmost importance, and we respect your privacy.

        <h3>6. User Rights</h3>
        As a Spendora user, you have the right to:

        Access your data at any point.
        Modify your data if it is inaccurate or incomplete.
        Delete your data from our database.
        Should you wish to exercise any of these rights, please reach out to our support team for assistance.

        <h3>7. Third-party Integrations</h3>
        Spendora does not incorporate third-party integrations, ensuring that your data remains uncompromised and solely within the app and iCloud database ecosystem.

        <h3>8. Changes to This Privacy Policy</h3>
        Lilohass reserves the right to make changes to this Privacy Policy. If significant changes are made, we will notify our users through appropriate channels. It is advised to review this policy periodically to stay informed.

        <h3>9. Contact Us</h3>
        For any queries, clarifications, or concerns regarding this Privacy Policy, please reach out to us.
      </div>
    </article>
  );
}

export default Spendora;
