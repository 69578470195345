import '../App.css';

function Home() {
  return (
    <div>
        <article style={{padding: '1rem'}}>
            <header>
                <h1>Contact</h1>
                <p>Fill the form to contact us</p>
            </header>
        </article>
        <iframe title="Contact Form" src="https://docs.google.com/forms/d/e/1FAIpQLSdhnPSDltJ6W1w8-PdLXxhD--dk9iPQu3X62ab4iK5PkS2C7g/viewform?embedded=true" width="440" height="1200" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  );
}

export default Home;