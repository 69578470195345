import '../App.css';
import spendoraLogo from '../img/spendora/spendora-logo-large.png';

function Home() {
  return (
    <article style={{padding: '1rem'}}>
        <header>
            <h1>LiloHass Apps</h1>
            <p>This website's purpose is to showcase information and links to apps developed by LiloHass</p>
        </header>
        <p>
            <h3>Apps</h3>
            Below you can find our apps
            <h4>Spendora</h4>
            <img src={spendoraLogo} alt="spendora app logo" style={{width: "4rem", borderRadius: "0.4rem"}}/>
            <p>Simplify your finance management! Track expenses, manage bank accounts, visualize transactions with charts, and import CSV files. Manage your budget efficiently!</p>
            <a href='/spendora'>View more</a>
        </p>
    </article>
  );
}

export default Home;